<template>
  <el-dialog
    visible
    @close="abortCreate"
  >
    <ContentHeader
      slot="title"
      :title="isModalEdit ?
        $t('samples.update.updateSample') : $t('samples.create.createSample')"
    />
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <el-form
        ref="form"
        label-position="top"
      >
        <el-row
          v-if="rename"
          :gutter="20"
        >
          <el-col :span="24">
            <FormItem
              :label="$t('samples.create.productName')"
            >
              <el-input
                v-model="formData.productName"
                :disabled="!editableFields.includes('productName')"
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          v-else
          :gutter="20"
        >
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.labSampleCode')"
              :help="$t('tooltips.newSample.labSampleCode')"
            >
              <el-input
                v-model="formData.productCode"
                :disabled="!editableFields.includes('productCode')"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              rules="required"
              :label="$t('samples.create.productName')"
              :help="$t('tooltips.newSample.productName')"
            >
              <el-input
                v-model="formData.productName"
                :disabled="!editableFields.includes('productName')"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.customerProductName')"
              :help="$t('tooltips.newSample.customerSampleCode')"
            >
              <el-input
                v-model="formData.customerProductName"
                :disabled="!editableFields.includes('customerProductName')"
              />
            </FormItem>
          </el-col>
          <el-col :span="6">
            <FormItem
              :label="$t('samples.create.lot')"
              :help="$t('tooltips.newSample.lot')"
            >
              <el-input
                v-model="formData.lot"
                :disabled="!editableFields.includes('lot')"
              />
            </FormItem>
          </el-col>
          <el-col :span="6">
            <FormItem
              :label="$t('samples.create.date')"
              :help="$t('tooltips.newSample.lotDate')"
            >
              <el-date-picker
                v-model="formData.date"
                :disabled="!editableFields.includes('date')"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
              />
            </FormItem>
          </el-col>
          <el-col :span="6">
            <FormItem
              :label="$t('samples.create.sector')"
              :help="$t('tooltips.newSample.sector')"
              rules="required"
            >
              <el-select
                v-model="formData.sector"
                :disabled="!editableFields.includes('sector')"
                placeholder=""
                clearable
              >
                <el-option
                  v-for="(option) in sectorsList"
                  :key="option.value"
                  :label="$t(`samples.sectors.${option.value}`)"
                  :value="option.value"
                />
              </el-select>
            </FormItem>
          </el-col>
          <el-col :span="6">
            <FormItem
              rules="required|numeric"
              :label="$t('samples.create.packagesNumber')"
              :help="$t('tooltips.newSample.lotNumber')"
            >
              <el-input
                v-model.number="formData.packagesNumber"
                :disabled="!editableFields.includes('packagesNumber')"
                type="number"
              />
            </FormItem>
          </el-col>
          <el-col :span="24">
            <FormItem
              :label="$t('samples.create.notes')"
              :help="$t('tooltips.newSample.note')"
            >
              <el-input
                v-model="formData.notes"
                :disabled="!editableFields.includes('notes')"
                type="textarea"
              />
            </FormItem>
          </el-col>
          <el-col
            v-if="userPermissions && userPermissions.includes('user')"
            :span="12"
          >
            <FormItem
              :label="$t('samples.create.finalCustomer')"
              :help="$t('tooltips.newSample.finalCustomer')"
            >
              <el-autocomplete
                v-model="formData.finalCustomer"
                :disabled="!editableFields.includes('finalCustomer')"
                style="width: 100%"
                :fetch-suggestions="finalCustomerList"
                value-key="label"
                placeholder=""
              />
            </FormItem>
          </el-col>
          <el-col
            v-if="userPermissions && userPermissions.includes('admin')"
            :span="12"
          >
            <FormItem
              :label="$t('samples.create.finalCustomer')"
              :help="$t('tooltips.newSample.finalCustomer')"
            >
              <el-autocomplete
                v-model="formData.finalCustomer"
                :disabled="!editableFields.includes('finalCustomer')"
                style="width: 100%"
                :fetch-suggestions="customersSearch"
                :trigger-on-focus="false"
                value-key="label"
                placeholder=""
                @select="handleSelectCustomer"
                @input="customerId = null"
              />
            </FormItem>
          </el-col>
          <el-col :span="12">
            <FormItem
              :label="$t('samples.create.finalCustomerRef')"
              :help="$t('tooltips.newSample.finalCustomerRef')"
            >
              <el-input
                v-model="formData.finalCustomerRef"
                :disabled="!editableFields.includes('finalCustomerRef')"
              />
            </FormItem>
          </el-col>
          <el-col :span="12">
            <FormItem
              rules="required"
              :label="$t('samples.create.sampleDescription')"
              :help="$t('tooltips.newSample.description')"
            >
              <el-select
                v-model="formData.sampleDescription"
                :disabled="!editableFields.includes('sampleDescription')"
                placeholder=""
                clearable
              >
                <el-option
                  v-for="(option) in sampleDescriptionsList"
                  :key="option.value"
                  :label="$t(`samples.sampleDescriptions.${option.value}`)"
                  :value="option.value"
                />
              </el-select>
            </FormItem>
          </el-col>
          <el-col :span="12">
            <FormItem
              :label="$t('samples.create.costCenter')"
              :help="$t('tooltips.newSample.costCenter')"
            >
              <el-input
                v-model="formData.costCenter"
                :disabled="!editableFields.includes('costCenter')"
              />
            </FormItem>
          </el-col>
          <el-col
            v-if="formData.finalCustomer || customerId"
            :span="8"
          >
            <FormItem
              :label="$t('samples.create.customerHeaderData')"
            >
              <el-switch
                v-model="formData.customerHeaderData"
                :disabled="!editableFields.includes('customerHeaderData')"
                :active-text="$t('samples.create.customerHeaderDataYes')"
                :inactive-text="$t('samples.create.customerHeaderDataNo')"
                @change="resetAddress"
              />
            </FormItem>
          </el-col>
          <el-col
            v-if="formData.customerHeaderData"
            :span="16"
          >
            <FormItem
              :label="$t('samples.create.customerAddress')"
            >
              <el-select
                v-model="formData.addressId"
                :disabled="!editableFields.includes('addressId')"
                @change="selectAddress"
              >
                <el-option
                  :value="null"
                  :label="$t('samples.create.address.addNew')"
                />
                <el-option
                  v-for="item in addresses"
                  :key="item.id"
                  :value="item.id"
                  :label="`${item.name} - ${item.address}`"
                />
              </el-select>
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          v-if="formData.customerHeaderData"
          :gutter="20"
        >
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.address.name')"
            >
              <el-input
                v-model="address.name"
                :maxlength="40"
                :disabled="formData.addressId !== null"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.address.address')"
            >
              <el-input
                v-model="address.address"
                :maxlength="40"
                :disabled="formData.addressId !== null"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.address.city')"
            >
              <el-input
                v-model="address.city"
                :maxlength="40"
                :disabled="formData.addressId !== null"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.address.zip')"
            >
              <el-input
                v-model="address.zip"
                :maxlength="6"
                :disabled="formData.addressId !== null"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.address.province')"
            >
              <el-input
                v-model="address.province"
                :maxlength="2"
                :disabled="formData.addressId !== null"
              />
            </FormItem>
          </el-col>
          <el-col :span="8">
            <FormItem
              :label="$t('samples.create.address.phoneNumber')"
            >
              <el-input
                v-model="address.phoneNumber"
                :maxlength="14"
                :disabled="formData.addressId !== null"
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <FormItem
              :label="$t('samples.create.comments')"
              :help="$t('tooltips.newSample.comments')"
            >
              <el-input
                v-model="formData.comments"
                :disabled="!editableFields.includes('comments')"
                type="textarea"
              />
            </FormItem>
          </el-col>

          <el-col :span="24">
            <FormItem
              :label="$t('global.uploadPdf')"
              :help="$t('tooltips.newSample.pdfUpload')"
            >
              <div
                v-for="(attachment, index) in previouslyUploadedFiles"
                :key="index"
                class="attachments-list"
              >
                <span class="attachment-filename">{{ attachment.filename }}</span>
                <div>
                  <el-button
                    type="primary"
                    icon="el-icon-download"
                    circle
                    @click.prevent="downloadAttachedPdf(attachment)"
                  />
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click.prevent="removeAttachedSamplePdf(attachment)"
                  />
                </div>
              </div>
              <el-upload
                ref="uploadComponent"
                action=""
                accept="application/pdf"
                multiple
                :on-change="handleChangeAttachment"
                :on-remove="handleRemoveAttachment"
                :before-remove="beforeRemoveAttachment"
                :auto-upload="false"
              >
                <el-button
                  size="small"
                  type="primary"
                >
                  {{ $t('global.browse') }}
                </el-button>
                <div
                  slot="tip"
                  class="el-upload__tip"
                >
                  {{ $t('samples.create.fileSize') }}
                </div>
              </el-upload>
            </FormItem>
          </el-col>
        </el-row>
        <FormButtons>
          <el-button
            type="secondary"
            @click.prevent="abortCreate"
          >
            {{ $t('samples.create.abortCreate') }}
          </el-button>
          <el-button
            v-if="isModalEdit"
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(handleUpdate)"
          >
            {{ $t('samples.update.updateSample') }}
          </el-button>
          <el-button
            v-else
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(createSample)"
          >
            {{ $t('samples.create.createSample') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import fileSaver from 'file-saver';
import moment from 'moment';
import { mapGetters } from 'vuex';

import { ordersApi, samplesApi, customersApi } from '@/api';
import FormItem from '@/components/FormItem.vue';
import ContentHeader from '@/components/ContentHeader.vue';
import FormButtons from '@/components/FormButtons.vue';
import { sectorsList, sampleDescriptionsList, SAMPLE_STATUSES } from '@/config/constants';

const defaultData = {
  productCode: null,
  productName: null,
  customerProductName: null,
  lot: null,
  date: null,
  sector: null,
  packagesNumber: null,
  notes: null,
  finalCustomer: null,
  finalCustomerRef: null,
  customerAddress: null,
  customerHeaderData: false,
  sampleDescription: null,
  comments: null,
  costCenter: null,
  orderId: null,
  addressId: null,
};

const defaultAddressData = {
  name: null,
  address: null,
  city: null,
  phoneNumber: null,
  province: null,
  zip: null,
};

export default {
  name: 'CreateSample',
  components: {
    FormItem,
    ContentHeader,
    FormButtons,
  },
  props: {
    rename: { type: Boolean, default: false },
    order: { type: Object, default: () => {} },
    visible: { type: Boolean, default: false },
    isModalEdit: { type: Boolean, default: false },
    initialData: { type: Object, default: () => defaultData },
  },
  data() {
    return {
      formData: { ...defaultData },
      sectorsList,
      sampleDescriptionsList,
      attachedFiles: [],
      previouslyUploadedFiles: [],
      addresses: [],
      customerSearch: null,
      customerId: null,
      address: { ...defaultAddressData },
    };
  },
  computed: {
    ...mapGetters([
      'userPermissions',
      'loggedUser',
    ]),
    isCloneMode() {
      return !this.isModalEdit && this.$route.params.sampleId;
    },
    editableFields() {
      if (this.isModalEdit) {
        switch (this.initialData.status) {
          case SAMPLE_STATUSES.CREATED:
            return Object.keys(this.formData);
          case SAMPLE_STATUSES.PENDING:
            return [
              'productCode',
              'productName',
              'customerProductName',
              'lot',
              'date',
              'notes',
              'finalCustomer',
              'finalCustomerRef',
              'costCenter',
              'status',
            ];
          case SAMPLE_STATUSES.DRAFT:
            return ['productName'];
          case SAMPLE_STATUSES.ACCEPTED:
          default:
            return [];
        }
      } else {
        return Object.keys(this.formData);
      }
    },
  },
  watch: {
    initialData: {
      immediate: true,
      handler(data) {
        if ((this.isModalEdit || this.isCloneMode) && Object.keys(data).length > 0) {
          this.formData = { ...data };
          this.formData.customerHeaderData = !!data.addressId;
        }
      },
    },
  },
  async created() {
    if (
      this.userPermissions
      && this.userPermissions.includes('user')
      && this.loggedUser.customerId) {
      const addresses = await customersApi.getCustomerAddresses(this.loggedUser.customerId);
      this.addresses = addresses.data;
    }
    if (
      this.isModalEdit
      && this.userPermissions
      && this.userPermissions.includes('admin')
    ) {
      if (this.formData.addressId) {
        const address = await customersApi.getCustomerAddress(this.formData.addressId);
        if (address.data.customer) {
          this.customerSearch = address.data.customer.name;
          this.customerId = address.data.customer.id;
          this.address = address.data;
          const addresses = await customersApi.getCustomerAddresses(address.data.customer.id);
          this.addresses = addresses.data;
        }
      } else if (this.formData.finalCustomer) {
        const customer = await customersApi.findCustomerByName(this.formData.finalCustomer);
        if (customer.data[0]) {
          this.customerId = customer.data[0].id;
          const addresses = await customersApi.getCustomerAddresses(this.customerId);
          this.addresses = addresses.data;
        }
      }
      if (this.isModalEdit) {
        const attachments = await samplesApi.getSampleAttachments(this.$route.params.sampleId);
        this.previouslyUploadedFiles = attachments.data || [];
      }
    }
  },
  methods: {
    buildFormToSend() {
      if (this.rename) {
        return { productName: this.formData.productName };
      }
      if (this.isModalEdit) {
        const keys = Object.keys(this.initialData);
        const usedKeys = keys
          .filter(item => this.initialData[item] !== this.formData[item] && item);
        const data = {};
        usedKeys.forEach((key) => {
          data[key] = this.formData[key];
        });
        return data;
      }
      return Object.assign(
        // required fields
        {
          productName: this.formData.productName,
          packagesNumber: this.formData.packagesNumber,
          sampleDescription: this.formData.sampleDescription,
          date: moment.utc(this.formData.date).startOf('day').toDate(),
          orderId: this.order.id,
        },
        // optional fields
        this.formData.productCode !== null && {
          productCode: this.formData.productCode,
        },
        this.formData.customerProductName !== null && {
          customerProductName: this.formData.customerProductName,
        },
        this.formData.lot !== null && {
          lot: this.formData.lot,
        },
        this.formData.sector !== null && {
          sector: this.formData.sector,
        },
        this.formData.notes !== null && {
          notes: this.formData.notes,
        },
        this.formData.finalCustomer !== null && {
          finalCustomer: this.formData.finalCustomer,
        },
        this.formData.finalCustomerRef !== null && {
          finalCustomerRef: this.formData.finalCustomerRef,
        },
        this.formData.customerAddress !== null && {
          customerAddress: this.formData.customerAddress,
        },
        this.formData.comments !== null && {
          comments: this.formData.comments,
        },
        this.formData.costCenter !== null && {
          costCenter: this.formData.costCenter,
        },
      );
    },
    async createSample() {
      try {
        // Create a new address if needed
        let newAddressId = null;
        if (this.formData.customerHeaderData && this.formData.addressId === null) {
          const newAddressResponse = await customersApi.createAddress(
            this.customerId || this.loggedUser.customerId,
            this.address,
          );
          newAddressId = newAddressResponse.data.id;
        }
        if (this.formData.addressId) newAddressId = this.formData.addressId;

        const sampleData = this.buildFormToSend();
        if (newAddressId) sampleData.addressId = newAddressId;
        // in clone sample id exist
        const sampleCreatedResponse = await ordersApi.createSample(
          this.order.id,
          sampleData,
        );
        // Uploads file if attached
        if (this.attachedFiles.length) {
          const formData = new FormData();
          this.attachedFiles.forEach(file => formData.append('pdf', file.raw, file.name));
          await samplesApi.uploadSamplePdf(sampleCreatedResponse.data.id, formData);
        }
        this.$notify({ showClose: true, type: 'success', message: this.$t('samples.create.success') });
        this.$emit('dialogClose', { success: true, sampleCreated: sampleCreatedResponse.data });
        this.formData = { ...defaultData };
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async updateSample() {
      try {
        // Create a new address if needed
        let newAddressId = null;
        if (this.formData.customerHeaderData && this.formData.addressId === null) {
          const newAddressResponse = await customersApi.createAddress(
            this.customerId || this.loggedUser.customerId,
            this.address,
          );
          newAddressId = newAddressResponse.data.id;
        }
        if (this.formData.addressId) newAddressId = this.formData.addressId;
        const sampleData = this.buildFormToSend();
        if (Object.keys(sampleData) === 'addressId') {
          sampleData.addressId = newAddressId;
        }

        await ordersApi.patchSample(
          this.$route.params.orderId,
          this.$route.params.sampleId,
          sampleData,
        );
        if (this.attachedFiles.length) {
          const formData = new FormData();
          this.attachedFiles.forEach(file => formData.append('pdf', file.raw, file.name));
          await samplesApi.uploadSamplePdf(this.$route.params.sampleId, formData);
        }
        this.$notify({
          showClose: true,
          type: 'success',
          message: this.$t('samples.update.success'),
        });
        this.$emit('dialogClose', {
          success: true,
          updatedSample: { ...this.formData, id: this.$route.params.sampleId },
        });
        this.formData = { ...defaultData };
      } catch (error) {
        this.$notify({
          showClose: true,
          type: 'error',
          message: error.response.data.error.message,
        });
      }
    },
    handleUpdate() {
      if (this.initialData.status === SAMPLE_STATUSES.PENDING) {
        this.openConfirmModal();
      } else {
        this.updateSample();
      }
    },
    openConfirmModal() {
      this.$confirm(this.$t('samples.update.confirm'), this.$t('global.warning'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'info',
      })
        .then(async () => {
          this.updateSample();
        })
        .catch((error) => {
          this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        });
    },
    abortCreate() {
      this.formData = { ...defaultData };
      this.$emit('dialogClose', { success: false });
    },
    async finalCustomerList(value, cb) {
      const results = await ordersApi.getFinalCustomerList();
      const filteredResults = results.data.filter(f => f !== null).map(item => (
        {
          label: item,
          value: item,
        }
      ));
      cb(filteredResults);
    },
    handleChangeAttachment(file) {
      const filesize = ((file.size / 1024) / 1024).toFixed(4);
      if (filesize < 10) {
        this.attachedFiles.push(file);
      } else {
        const uploadFileList = this.$refs.uploadComponent.uploadFiles;
        uploadFileList.splice(uploadFileList.indexOf(file), 1);
      }
    },
    handleRemoveAttachment(file) {
      this.attachedFiles = this.attachedFiles.filter(e => e !== file);
    },
    beforeRemoveAttachment() {
      return this.$confirm(this.$t('global.confirmUploadCancel'));
    },
    async removeAttachedSamplePdf(attachment) {
      try {
        this.$confirm(this.$t('global.confirmUploadCancel'), this.$t('global.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(async () => {
            await samplesApi.removeAttachedSamplePdf(this.$route.params.sampleId, attachment.id);
            this.previouslyUploadedFiles = this.previouslyUploadedFiles
              .filter(e => e !== attachment);
          });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async downloadAttachedPdf(attachment) {
      try {
        const file = await samplesApi.downloadAttachedSamplePdf(
          this.$route.params.sampleId, attachment.id,
        );
        if (file) {
          return fileSaver.saveAs(file.data, `attachment-${this.$route.params.sampleId}.pdf`);
        }
        return false;
      } catch (error) {
        return error;
      }
    },
    async customersSearch(value, cb) {
      if (value.length >= 3) {
        const results = await customersApi.autocompleteFetch(value);
        const filteredResults = results.data.map(item => (
          {
            label: item.name,
            value: item.id,
          }
        ));
        cb(filteredResults);
      } else {
        cb([]);
      }
    },
    async handleSelectCustomer(item) {
      const addresses = await customersApi.getCustomerAddresses(item.value);
      this.addresses = addresses.data;
      this.customerId = item.value;
    },
    selectAddress(addressId) {
      if (addressId === null) {
        this.address = { ...this.defaultAddressData };
      } else {
        const selectedAddress = this.addresses.find(a => a.id === addressId);
        const {
          name,
          address,
          city,
          phoneNumber,
          province,
          zip,
        } = selectedAddress;
        this.address = {
          name,
          address,
          city,
          phoneNumber,
          province,
          zip,
        };
      }
    },
    resetAddress() {
      this.address = { ...this.defaultAddressData };
      this.formData.addressId = null;
    },
  },
};
</script>

<style lang="scss">
.attachments-list {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  justify-content: space-between;

  .attachment-filename {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
